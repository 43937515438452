<template>
  <div class="sign-form">
    <el-form ref="refForm" label-width="140px" :model="dataForm" :rules="rules" :inline="false" size="small">
      <el-card class="sign-card">
        <!--        <el-form-item label="签约信息ID" v-if="dataForm.id">-->
        <!--          <span>{{ dataForm.id }}</span>-->
        <!--        </el-form-item>-->
        <el-form-item label="红人昵称" prop="nickname">
          <span>{{ dataForm.nickname }}</span>
        </el-form-item>
        <el-form-item label="签约年份" prop="year">
          <span>{{ dataForm.year }}年</span>
        </el-form-item>
        <el-form-item label="签约月份" prop="month">
          <span>{{ dataForm.month }}月</span>
        </el-form-item>
        <el-form-item label="签约日期" prop="sign_date">
          <span>{{ $utils.parseTime(dataForm.sign_date, '{y}/{m}/{d}') }}</span>
        </el-form-item>
        <el-form-item label="签约前平台信息">
          <BefPlatformInfo :data="dataForm.sign_bef_info"/>
        </el-form-item>
      </el-card>
      <el-card class="sign-card">
        <el-row>
          <el-col :span="8">
            <el-form-item label="签约人" prop="signatory">
              <span>{{ dataForm.signatory }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="红人真实姓名">
              <span>{{ dataForm.real_name }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="红人身份证号" prop="id_card">
              <span>{{ dataForm.id_card }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="签约后主平台" prop="platform_code_aft">
              <el-select v-model="dataForm.platform_code_aft" placeholder="选择 签约后平台" :clearable="true" disabled>
                <el-option
                    v-for="item in platformOptions"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="合约性质">
              <el-select v-model="dataForm.nature" placeholder="选择 合约性质" :clearable="true" disabled>
                <el-option :label="`全约`" :value="1">全约</el-option>
                <el-option :label="`商务约`" :value="2">商务约</el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="合约年限" prop="term">
              <span>{{ dataForm.term }} 年</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="续约年限">
              <span>{{ dataForm.renewal_years }} 年</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="续约条件（万）">
              <span>{{ dataForm.renewal_cond }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="博主分成比例">
              <span>{{ dataForm.blogger_ratio }} %</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="账号来源">
              <span>{{ dataForm.account_source }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="分配组别" prop="group_id">
              <span>{{ dataForm.group_name }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注">
              <span>{{ dataForm.remark }}</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>

    <el-row>
      <el-col :span="8" style="text-align: center;width: 100%">
        <el-button type="default" @click="editButton" v-if="userPermissions.indexOf('sign_enter_page') > -1">
          <i class="el-icon-edit"></i> 编辑- 签约信息
        </el-button>
      </el-col>
    </el-row>

  </div>
</template>

<script>

import { mapGetters } from "vuex";
import BefPlatformInfo from "@/pages/sign_info/components/BefPlatformInfo";

export default {
  name: "SignInfoDetail",
  components: {
    BefPlatformInfo
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  watch: {
    // eslint-disable-next-line
    '$route'(to, from) {
      this.initForm()
    }
  },
  data() {
    return {
      id: null,
      dataForm: { year: null, month: null, sign_date: null, group_id: null },
      monthOptions: [],
      platformOptions: [],
      rules: {
        nickname: [{ required: true, message: '输入红人昵称', trigger: 'blur' }],
        signatory: [{ required: true, message: '输入签约人', trigger: 'blur' }],
        sign_date: [{ required: true, message: '输入签约时间', trigger: 'blur' }],
        id_card: [{ required: true, message: '输入身份证号', trigger: 'blur' }],
        group_id: [{ required: true, message: '分组必选', trigger: 'blur' }],
        term: [{ required: true, message: '输入合约年限', trigger: 'blur' }],
        platform_code_aft: [{ required: true, message: '输入签约后主平台', trigger: 'blur' }],
      },
      accountSourceOptions: [
        { label: '组内提供微信', val: 'pwg' },
        { label: '自己寻找', val: 'fys' },
      ],
    }
  },
  created() {
    this.initOptions()
  },
  mounted() {
    this.initForm()
  },
  methods: {
    //初始化月份下拉框
    initOptions() {
      this.monthOptions = [];
      for (let i = 1; i <= 12; i++) {
        this.monthOptions.push({ value: i, label: i + '月份' })
      }
      this.getPlatforms()
    },
    async getPlatforms() {
      // 获取最新平台信息
      let { list } = await this.$api.getPlatforms()
      this.platformOptions = list
    },
    changeSignDate() {
      let sign_date = this.dataForm.sign_date;
      if (sign_date.indexOf('-') > -1) {
        let arr = sign_date.split('-')
        this.dataForm.year = arr[0]
        this.dataForm.month = parseInt(arr[1])
      }

    },
    async editButton() {
      let id = this.$route.params.sign_id
      this.$router.push('/sign/' + id + '/edit')
    },
    // 确认保存按钮
    async saveConfirm() {
      // 调用组件的数据验证方法
      this.$refs['refForm'].validate((valid) => {
        if (valid) {
          // console.log(this.formData)
          this.formSubmit()
        } else {
          this.$message.error('数据验证失败，请检查必填项数据！')
        }
      })
      return true
    },
    // 相关信息的保存处理
    async formSubmit() {
      let id = await this.$api.saveSignInfo(this.dataForm)
      if (id) {
        this.$notify.success('保存成功！')
        this.editPage(id)
        // this.initForm()
      }
      // if (!this.artistId && id) {
      //   // this.$router.push(this.$route.matched[1].path+'/'+id+'/edit')
      //   this.$router.push(this.$route.matched[1].path)
      // }
    },
    // 选择分组
    selectGroup(val) {
      this.dataForm.group_id = val
    },
    initForm() {
      //
      this.id = this.$route.params.sign_id
      if (this.id) {
        this.getDetail()
      } else {
        this.dataForm = { year: null, month: null, sign_date: null, group_id: null }
      }
    },
    async getDetail() {
      let { info } = await this.$api.getSignInfo(this.id)
      info.account_source = this.accountSourceFormat(info)
      this.dataForm = info
    },
    // 响应编辑按钮
    async editPage(id) {
      this.$router.push('/sign/' + id + '/edit')
    },
    // eslint-disable-next-line no-unused-vars
    accountSourceFormat(row, column) {
      return this.selectDictLabel(this.accountSourceOptions, row.account_source);
    },
    // 回显数据字典
    selectDictLabel(datas, value) {
      var actions = [];
      Object.keys(datas).map((key) => {
        if (datas[key].val === value) {
          actions.push(datas[key].label);
          return false;
        }
      })
      return actions.join('');
    },
  },
  destroyed() {
    this.dataForm = { year: null, month: null, sign_date: null, group_id: null }
  }

}
</script>

<style scoped>
.sign-form {
  padding-top: 20px;
  width: 1000px;
  min-width: 900px;
  margin: auto;
  height: 100vh;
}

.sign-card {
  margin-bottom: 20px;
}
</style>
